.card-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.cardss {
  text-align: center;
}

.card-number {
  font-weight: bold;
  font-size: 18px;
  color: #bcbcbc;
  display: block;
  margin-bottom: 10px;
}

.card-image {
  width: 100%;
  border-radius: 16px;
  margin-bottom: 10px;
}

.card-text {
  color: #555555;
  font-size: 14px;
}

.main-content {
  text-align: center;
  padding: 0 40px;
  max-width: 500px;
}

.main-content h1 {
  font-size: 36px;
  color: #333333;
  margin-bottom: 20px;
}

.main-content p {
  color: #666666;
  font-size: 16px;
  margin-bottom: 30px;
}

.explore-button {
  background-color: #000000;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
}

.explore-button:hover {
  background-color: #444444;
}

.intro-section {
  text-align: left;
  margin-bottom: 50px;
  padding: 50px;
  background: #fffae6;
}

.intro-section h2 {
  font-size: 18px;
  color: #999;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.intro-section h1 {
  font-size: 36px;
  color: #333;
  margin-bottom: 20px;
}

.intro-section p {
  font-size: 16px;
  color: #555;
  margin-bottom: 40px;
  max-width: 600px;
}

.concept-cards {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap; /* Wrap items on small screens */
}

.concept-card {
  background: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 48%;
}

.features-section {
  background: linear-gradient(135deg, #7d6aff, #fec3c5);
  padding: 50px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.features-section h1 {
  font-size: 32px;
  color: #333;
  text-align: center;
  margin-bottom: 40px;
}

.feature-cards {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap; /* Enable wrapping for small screens */
  gap: 20px;
}

.feature-card {
  background: rgba(255, 255, 255, 0.2); /* Semi-transparent background */
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%; /* Full width on smaller devices */
  max-width: 40%; /* Limit width on larger screens */
  text-align: center;
  backdrop-filter: blur(10px); /* Blur effect for frosted glass look */
  border: 1px solid rgba(255, 255, 255, 0.778); /* Light border for glass effect */
}

.feature-card h3 {
  font-size: 22px;
  color: #333;
  margin-bottom: 15px;
}

.feature-card p {
  font-size: 14px;
  color: #e5e5e5;
  margin-bottom: 20px;
}

.explore-link {
  color: #256aff;
  font-size: 14px;
  text-decoration: none;
}

.explore-link:hover {
  text-decoration: underline;
}


.footer-container {
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  background: linear-gradient(90deg, #7d6aff 0%,  35%, #fec3c5 100%);
  color: white;
}

.footer-left {
  width: 30%;
  background-image: url('./banner\ image1.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.footer-left .logo-section h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.footer-left .description h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.footer-left .description p {
  line-height: 1.8;
}

.footer-right {
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer-follow h3,
.footer-subscribe h3 {
  font-size: 1.2rem;
}

.footer-follow {
  display: flex;
  align-items: center;
}

.footer-follow .social-icons {
  display: flex;
  gap: 0.5rem;
}

.social-icons i {
  font-size: 1.5rem;
  cursor: pointer;
}

.footer-subscribe {
  margin: 2rem 0;
}

.footer-subscribe p {
  margin: 1rem 0;
}

.subscribe-form {
  display: flex;
  gap: 0.5rem;
}

.subscribe-form input {
  padding: 0.5rem;
  border-radius: 5px;
  border: none;
  width: 60%;
}

.subscribe-form button {
  padding: 0.5rem 1rem;
  border-radius: 5px;
  background: white;
  color: #ff4b2b;
  border: none;
  cursor: pointer;
}

.footer-links {
  display: flex;
  justify-content: space-between;
}

.column h4 {
  margin-bottom: 1rem;
}

.column ul {
  list-style-type: none;
  padding: 0;
}

.column ul li {
  margin: 0.5rem 0;
}

.column ul li:hover {
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    padding: 1rem;
  }

  .footer-left,
  .footer-right {
    width: 100%;
  }

  .footer-links {
    flex-direction: column;
  }
}



/* Media Queries for Responsive Design */

/* For tablets and smaller screens */
@media (max-width: 768px) {
  .card-container {
    flex-direction: column; /* Stack cards vertically */
    text-align: center;
  }

  .main-content {
    padding: 0 20px;
  }

  .feature-card {
    width: 100%; /* Full width for feature cards */
  }

  .concept-card {
    width: 100%; /* Full width for concept cards */
  }
  .concept-cards{
    flex-direction: column;
  }
}

/* For mobile devices */
@media (max-width: 480px) {
  .main-content h1 {
    font-size: 28px; /* Reduce font size */
  }

  .main-content p {
    font-size: 14px; /* Reduce font size */
  }

  .explore-button {
    padding: 8px 16px; /* Smaller button padding */
    font-size: 12px; /* Smaller font size */
  }

  .features-section h1 {
    font-size: 24px; /* Smaller heading for mobile */
  }

  .feature-card {
    padding: 15px;
    max-width: 100%; /* Full width on mobile */
  }

  .concept-card {
    padding: 15px;
    max-width: 100%; /* Full width on mobile */
  }
  .concept-cards{
    flex-direction: column;
  }
}
