/* Loader Styles */
.loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
  }
  
  .progress-message {
    margin-top: 20px;
    color: #ffffff;
  }
  
  /* Container for the Intro Form */
  .intro-container {
    display: flex;
    height: 100vh;
    flex-direction: row; /* To ensure question and image sections are side by side */
  }
  
  /* Question Section Styles */
  .question-section {
    flex: 1;
    padding: 50px;
    background-color: #ffffff;
  }
  
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .options {
    margin-bottom: 40px;
  }
  
  .option {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
  }
  
  .option.selected {
    background-color: #e6f7ff;
    border-color: #40a9ff;
  }
  
  .option label {
    font-size: 18px;
    color: #4d4d4d;
    margin-left: 10px;
  }
  
  .other-input {
    margin-top: 10px;
    padding: 8px;
    width: calc(100% - 20px);
    border: 1px solid #d9d9d9;
    border-radius: 5px;
  }
  
  .continue-btn, .skip-btn {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 16px;
  }
  
  .continue-btn {
    background-color: black;
    color: white;
  }
  
  .skip-btn {
    position: absolute;
    bottom: 20px;
    right: 20px;
    background-color: transparent;
    color: #112f5c;
  }
  
  /* Image Section Styles */
  .image-section {
    flex: 1;
    background:linear-gradient(135deg, rgba(75, 47, 255, 0.795), rgba(159, 115, 255, 0.6));
    color: #ffffff;
    text-align: center;
    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image-section img {
    max-width: 80%;
    max-height: 80%;
  }
  
  /* Loader Section Styles */
  .loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
  }
  
  .progress-message {
    margin-top: 20px;
    color: #ffffff;
  }
  
  .no-border {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #ccc; /* Optional: Add a bottom border */
    outline: none; /* Remove the default outline when focused */
  }
  
  .no-border:focus {
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc; /* Optional: Ensure the bottom border is visible */
  }
  
  /* Highlight the options container in red if there's an error */
  .options.error .option {
    border-color: red;
  }
  
  .options.error .selected {
    border-color: red;
  }
  
  /* Error message styling */
  .error-message {
    color: red;
    font-size: 14px;
    margin-top: -10px;
    margin-bottom: 20px;
  }
  
  
  
  
  