.theme-customizer {
  display: flex;
  justify-content: center;
  padding: 30px;
  background-color: #f0f2f5;
  min-height: 100vh;
}

.ant-card {
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.ant-card-title {
  font-size: 1.25rem;
  font-weight: 500;
}

.ant-btn {
  font-size: 1rem;
  padding: 10px 20px;
  transition: all 0.3s ease;
}

.ant-btn-primary {
  background-color: #1890ff;
  border-color: #1890ff;
}

.ant-btn:hover {
  background-color: #40a9ff;
  border-color: #40a9ff;
}

.ant-typography {
  color: #333;
}
