.cover {
    background: linear-gradient(135deg, #695acdc4, #ad6fffd1); /* Purple gradient */
    background-size: 200% 200%; /* To animate gradient */
    height: 250px;
    border-radius: 12px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); /* Soft shadow */
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    animation: gradientMove 5s ease infinite; /* Gradient animation */
}

/* Gradient background animation */
@keyframes gradientMove {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

/* Floating elements (like icons or small circles) */
.cover::before {
    content: '';
    position: absolute;
    width: 100px;
    height: 100px;
    background: rgba(255, 255, 255, 0.3); /* Light decoration */
    border-radius: 50%;
    top: 20%;
    left: 10%;
    animation: float 6s ease-in-out infinite;
}

/* Another floating element */
.cover::after {
    content: '';
    position: absolute;
    width: 150px;
    height: 150px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    bottom: 10%;
    right: 15%;
    animation: float 8s ease-in-out infinite;
}

/* Floating animation for decor elements */
@keyframes float {
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(-20px);
    }
    100% {
        transform: translateY(0px);
    }
}

/* Optional: Decorative text in the center */
.cover .text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 2rem;
    font-weight: bold;
    /* text-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); */
    animation: fadeIn 2s ease-in-out;
}

/* Fade-in effect for text */
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translate(-50%, -60%);
    }
    100% {
        opacity: 1;
        transform: translate(-50%, -50%);
    }
}
