/* Overall layout */
.feedback-container {
    animation: fadeIn 0.5s ease-out;
  }
  
  .feedback-box {
    background: linear-gradient(135deg, #ffffff, #f8f9fb);
    /* border-radius: 10px; */
    padding: 50px;
    text-align: center;
    /* box-shadow: 0 12px 30px rgba(0, 0, 0, 0.1); */
    transition: transform 0.4s ease, box-shadow 0.4s ease;
  }
  
  .feedback-box:hover {
    transform: translateY(-10px);
    box-shadow: 0 16px 40px rgba(0, 0, 0, 0.15);
  }
  
  .header-text {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 15px;
    color: #333;
  }
  
  .subtext {
    font-size: 16px;
    color: #777;
    margin-bottom: 30px;
  }
  
  /* Rating section */
  .rating-text {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
  }
  
  .rating-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 35px;
  }
  
  .rating-button {
    border: 1px solid #6F5CE5;
    background-color: #fff;
    color: #6F5CE5;
    padding: 12px 20px;
    margin: 0 8px;
    border-radius: px;
    font-size: 18px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .rating-button:hover, .rating-button.selected {
    background-color: #6F5CE5;
    color: #fff;
    transform: scale(1.15);
    box-shadow: 0 8px 20px rgba(0, 123, 255, 0.3);
  }
  
  /* Feedback text area */
  .feedback-input {
    margin-bottom: 40px;
  }
  
  .feedback-input textarea {
    width: 100%;
    height: 140px;
    padding: 20px;
    border: 2px solid #ccc;
    border-radius: 10px;
    font-size: 16px;
    transition: border-color 0.3s ease;
  }
  
  .feedback-input textarea:focus {
    border-color: #6F5CE5;
    outline: none;
  }
  
  /* Action buttons */
  .feedback-actions {
    display: flex;
    justify-content: space-between;
  }
  
  .action-button {
    padding: 12px 30px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .action-button.back {
    background-color: #f0f0f0;
    color: #333;
  }
  
  .action-button.maybe-later {
    background-color: #ffc107;
    color: #fff;
  }
  
  .action-button.submit {
    background-color: #6F5CE5;
    color: #fff;
  }
  
  .action-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 123, 255, 0.2);
  }
  
  /* Animations */
  .animated {
    animation-duration: 0.6s;
    animation-fill-mode: both;
  }
  
  .fadeIn {
    animation-name: fadeIn;
  }
  
  .fadeInUp {
    animation-name: fadeInUp;
  }
  
  .zoomIn {
    animation-name: zoomIn;
  }
  
  .slideInUp {
    animation-name: slideInUp;
  }
  
  .pulse {
    animation-name: pulse;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
  }
  
  .shake {
    animation: shake 0.5s;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translate3d(0, 40px, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  
  @keyframes zoomIn {
    from {
      opacity: 0;
      transform: scale(0.5);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  @keyframes slideInUp {
    from {
      transform: translate3d(0, 100%, 0);
    }
    to {
      transform: translate3d(0, 0, 0);
    }
  }
  
  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes pulse {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
  }
  
  /* Completion message */
  .feedback-completed {
    text-align: center;
    padding: 60px;
    background-color: #00801eba;
    border-radius: 12px;
    color: white;   
    /* box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2); */
  }
  
  .feedback-completed h2 {
    font-size: 28px;
    margin-bottom: 15px;
  }
  
  .feedback-completed p {
    font-size: 18px;
  }
  
  .success-icon {
    width: 60px;
    height: 60px;
    background-color: white;
    border-radius: 50%;
    margin: 0 auto;
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.1);
  }
  