.main-container {
  width: 100%;
  background: linear-gradient(135deg, #fdeff5, #f6f2ff);
  margin: 0;
  /* font-family: 'Poppins', sans-serif; */
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
  background: transparent;
}

.logo {
  display: flex;
  align-items: center;
}

.logo-img {
  height: 40px; /* Adjust this to fit your logo */
}



.nav-list a:hover {
  color: #7d6aff;
}

.quote-button {
  background: #7d6aff;
  color: #fff;
  padding: 5px 15px;
  border-radius: 20px;
  transition: background 0.3s;
}

.quote-button:hover {
  background: #6f5ce5;
}

.hero-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0px 0px 50px;
}

.text-content {
  max-width: 500px;
}

.text-content h1 {
  font-size: 3rem;
  font-weight: 700;
  color: #2d2d2d;
}

.text-content .highlight {
  color: #9f73ff;
}

.text-content .description {
  margin: 20px 0;
  font-size: 1rem;
  color: #6d6d6d;
}

.email-input-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
  background: #fff;
  border-radius: 50px;
  padding: 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.email-input {
  padding: 10px 20px;
  border: none;
  border-radius: 50px;
  box-shadow: none;
  flex: 1;
  font-size: 1rem;
  color: #b3b3b3;
  background-color: transparent;
  outline: none;
}

.download-button {
  padding: 10px 20px;
  background: linear-gradient(135deg, #7d6aff, #FEC3C5);
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.download-button:hover {
  background: linear-gradient(135deg, #FEC3C5, #7d6aff);
  transition: background 0.3s;
}

.send-icon {
  margin-left: 8px;
  width: 16px;
  height: 16px;
  text-align: center;
  background-image: url('./send-icon.png'); 
  background-size: cover;
  display: inline-block;
}

.image-content {
  flex: 1;
  display: flex;
  justify-content: center;
}

.image-preview {
  max-width: 80%;
  border-radius: 20px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

.text-content h1 {
  font-size: 4rem; /* Default size for larger screens */
}

.text-content h3 {
  font-size: 1.5rem; /* Default size for larger screens */
}

.text-content .description {
  font-size: 1rem; /* Default size for larger screens */
}

.email-input {
  font-size: 1rem; /* Default size for larger screens */
}

@media (max-width: 576px) { /* Target small mobile screens */
  .text-content h1 {
    font-size: 3rem; 
    padding: 10px;/* Adjusted size for mobile */
  }

  .text-content h3 {
    font-size: 1.3rem; /* Adjusted size for mobile */
    padding: 10px;
  }

  .text-content .description {
    font-size: 0.9rem; /* Adjusted size for mobile */
    padding: 10px;
  }

  .email-input {
    font-size: 0.9rem; /* Adjusted size for mobile */
  }

  .download-button {
    font-size: 0.9rem; /* Adjust button font size for mobile */
  }
}

@media (max-width: 500px) {
  .logo-img {
    height: 30px; /* Adjust this to fit your logo */
  }
}



.message {
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
}

.success {
  background-color: #d4edda;
  color: #155724;
}

.error {
  background-color: #f8d7da;
  color: #721c24;
}
