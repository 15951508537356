.form-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .header{
    font-size: 20px;
    font-weight: 600;
    margin-left: 13.5%;
  }
  
  .variant-container {
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #fff;
  }
  
  .variant-container input {
    display: block;
    width: calc(100% - 20px);
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .sub-variant-container {
    display: flex;
    align-items: center;
    margin-left: 20px;
    padding-left: 20px;
    margin-top: 20px;
    border-left: 2px solid #007bff;
  }
  
  .image-upload-label {
    position: relative;
    cursor: pointer;
  }
  
  .image-upload-input {
    display: none;
  }
  
  .image-upload-icon {
    font-size: 24px;
    margin-right: 10px;
    color: #007bff;
    
  }
  
  .uploaded-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  
  .sub-variant-input {
    flex: 1;
    margin-right: 10px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .add-sub-variant-btn, .remove-sub-variant-btn {
    display: inline-block;
    margin: 5px 0;
    padding: 5px 10px;
    border: none;
    background-color: #f44336;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .add-variant-btn, .submit-btn {
    display: block;
    margin-top: 20px;
    padding: 10px;
    border: none;
    background-color: #0c6aad;
    color: white;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .final-button{
    display: flex;
    justify-content: end;
    gap: 10px;
  }
  
  .submit-btn{
    width: 20%;
  }
  
  .add-variant-btn{
    width: 20%;
    background-color: #4CAF50;
  }
  
  .add-sub-variant-btn {
    background-color: #4CAF50;
  }
  
  .remove-sub-variant-btn {
    background-color: #f44336;
  }
  
  .add-variant-btn:hover, .submit-btn:hover, .add-sub-variant-btn:hover, .remove-sub-variant-btn:hover {
    opacity: 0.9;
  }
  
  
  /* additional info change*/
  
  .info-header{
    font-size: 20px;
    font-weight: 600;
  }
  
  .info-form {
    display: flex;
    flex-direction: column;
  }
  
  .info-pair-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .info-input {
    flex: 1;
    margin-right: 10px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .remove-info-icon {
    font-size: 24px;
    margin-left: 10px;
  }
  
  .add-info-btn {
    margin-top: 10px;
    color: #007bff;
    cursor: pointer;
  }
  
  .add-info-btn:hover {
    text-decoration: underline;
  }
  
  .submit-btn {
    margin-top: 10px;
  }
  