/* Add these styles to your main CSS file or inline */

.containerfluid {
    background: linear-gradient(135deg, #8ebbff 0%, #aa00ff 100%);
    /* background: linear-gradient(135deg, #fdeff5, #f6f2ff); */
    padding: 60px 0;
  }
  
  h1 {
    text-align: center;
    font-size: 2.5rem;
    font-weight: bold;
  }
  
  h1 span {
    color: #e3e3e3;
  }
  
  .domain-search-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
  }
  
  .search-input {
    width: 500px;
    padding: 12px;
    border: none;
    border-radius: 40px;
    outline: none;
    font-size: 1.2rem;
  }
  
  .search-button {
    background-color: #0062ff;
    color: #fff;
    border: none;
    border-radius: 40px;
    padding: 12px 30px;
    margin-left: -70px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .features {
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
  }
  
  .feature-box {
    width: 30%;
    text-align: center;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
  }
  
  .feature-box .icon {
    font-size: 2rem;
    color: #00b1ff;
  }
  
  .feature-box h4 {
    margin-top: 10px;
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .feature-box p {
    font-size: 0.9rem;
    color: #666;
  }
  