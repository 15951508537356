.theme-card-container {
  position: relative;
}

.theme-preview-overlay {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 1; /* Ensure it’s above the image */
}

.theme-card-container:hover .theme-preview-overlay {
  opacity: 1;
}

.theme-card-container .card-body {
  position: relative;
}

.theme-preview-overlay a {
  color: #fff; /* Ensure link text is white */
  text-decoration: none; /* Optional: remove underline */
  display: flex;
  align-items: center;
}

.theme-preview-overlay a:hover {
  text-decoration: underline; /* Optional: add underline on hover */
}

.card-footer {
  position: relative; /* Ensures footer is not affected by overlay */
}

.theme-preview-overlay button {
  background: #007bff; /* Adjust button color if needed */
  border: none;
  padding: 10px 20px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
}




.input-container {
  position: relative;
  display: flex;
  height: 2.8rem;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 20px 20px 30px rgba(0, 0, 0, .05);
}

.input-container input {
  height: 100%;
  width: 100%;
  border-radius: 8px;
  border: 1px solid  rgb(176 190 197);
  background-color: transparent;
  padding: 0.625rem 70px 0.625rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  color: rgb(69 90 100);
  outline: none;
  transition: all .15s cubic-bezier(0.4, 0, 0.2, 1);
}

.input-container input:focus {
  border: 1px solid #6F5CE5;
}

.invite-btn {
  position: absolute;
  width: 150px;
  right: 4px;
  top: 4px;
  bottom: 4px;
  z-index: 10;
  border-radius: 4px;
  background-color: #6F5CE5;
  color: #fff;
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-align: center;
  vertical-align: middle;
  font-size: 12px;
  font-weight: 600;
  border: none;
  transition: .6s ease;
}

.invite-btn:hover {
  right: 2px;
  top: 2px;
  bottom: 2px;
  border-radius: 8px;
}

.input-container input:placeholder-shown ~ .invite-btn {
  pointer-events: none;
  background-color: #6F5CE5;
  opacity: 0.5;
}