.table-container {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .custom-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .custom-table th,
  .custom-table td {
    padding: 12px;
    text-align: left;
  }
  
  .custom-table thead {
    background-color: #f5f5f5;
  }
  
  .custom-table tbody tr {
    border-bottom: 1px solid #ddd;
  }
  
  .custom-table .user-info {
    display: flex;
    align-items: center;
  }
  
  .custom-table .avatar {
    background-color: #7f56d9;
    color: white;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }
  
  .custom-table .name {
    font-weight: bold;
  }
  
  .custom-table .username {
    color: #888;
  }
  
  .custom-table .status {
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 14px;
    display: inline-block;
  }
  
  .custom-table .status.active {
    background-color: #d1fae5;
    color: #059669;
  }
  
  .custom-table .status.offline {
    background-color: #f3f4f6;
    color: #6b7280;
  }
  
  .custom-table .status.wait {
    background-color: #fef3c7;
    color: #d97706;
  }
  
  .custom-table .rating {
    color: #eab308;
    font-size: 16px;
  }
  
  .custom-table .rating .filled {
    color: #fbbf24;
  }
  
  .custom-table .actions {
    display: flex;
    gap: 8px;
  }
  
  .custom-table .icon-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
    padding: 5px;
  }
  
  .custom-table .icon-button.info {
    color: #3b82f6;
  }
  
  .custom-table .icon-button.edit {
    color: #10b981;
  }
  
  .custom-table .icon-button.delete {
    color: #ef4444;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    padding: 20px 0;
    gap: 8px;
  }
  
  .pagination button {
    border: 1px solid #ddd;
    padding: 6px 12px;
    cursor: pointer;
  }
  
  .pagination button.active {
    background-color: #5369F8;
    color: white;
  }
  
  .pagination span {
    padding: 6px 12px;
  }
  