/* src/components/InvoicePrintComponent.css */

.invoice-print-container {
    padding: 20px;
    color: #333;
    border: 1px solid #ddd;
    border-radius: 5px;
    background: #fff;
  }
  
  .invoice-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .invoice-header h1 {
    margin: 0;
    font-size: 24px;
    color: #007bff;
  }
  
  .invoice-body h2 {
    font-size: 18px;
    margin-top: 20px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
    color: #007bff;
  }
  
  .invoice-body p {
    margin: 5px 0;
  }
  
  .invoice-body strong {
    display: inline-block;
    width: 150px;
  }
  